/* requires:
core-utils.js
*/
var MDM = MDM || {};
MDM.SharedNav = MDM.SharedNav || {};
MDM.SharedNav.Core = MDM.SharedNav.Core || {};

MDM.SharedNav.Core.stateManagementInstance = null;
MDM.SharedNav.Core.StateManagement = class {
    constructor() {
        this.checkSessionEventId = null;

        if (MDM.SharedNav.Utils.isLogoutPage()) {
            this.checkLocalSession()
                .then((response) => {
                    var parsedResponse = JSON.parse(response);
                    //init anonymous chat
                    MDM.SharedNav.Core.StateManagement.triggerAnonymousProcess(parsedResponse);
                });
            } else {
            //start checking the user authentication status locally
            this.isAuthenticatedLocal();
        }
    }

    static triggerAllProcess(navSettings) {
        if (navSettings.isMedalliaEnabled === true) {
            //init Medallia
            MDM.SharedNav.Template.medalliaInstance = new MDM.SharedNav.Template.Medallia(navSettings.medalliaSrc);
        }
    }

    static triggerAuthenticatedProcess(navSettings) {
        //init common processes
        MDM.SharedNav.Core.StateManagement.triggerAllProcess(navSettings);
        //init authenticated chat
        MDM.SharedNav.Template.Chat.MdChatInstance = new MDM.SharedNav.Template.Chat(MDM.SharedNav.Constants.AuthStatus.AUTHENTICATED, navSettings);
        //init splash page check
        MDM.SharedNav.Template.SplashPageInstance = new MDM.SharedNav.Template.SplashPage();
        //switch nav state
        MDM.SharedNav.Utils.switchNavState(MDM.SharedNav.Constants.AuthStatus.AUTHENTICATED, navSettings);
    }

    static triggerIsAuthenticatingProcess(navSettings) {
        MDM.SharedNav.Utils.switchNavState(MDM.SharedNav.Constants.AuthStatus.AUTHENTICATING, navSettings);
    }

    static triggerAnonymousProcess(navSettings) {
        //init common processes
        MDM.SharedNav.Core.StateManagement.triggerAllProcess(navSettings);
        //init anonymous chat
        MDM.SharedNav.Template.Chat.MdChatInstance = new MDM.SharedNav.Template.Chat(MDM.SharedNav.Constants.AuthStatus.ANONYMOUS, navSettings);
        //switch nav state to anonymous
        MDM.SharedNav.Utils.switchNavState(MDM.SharedNav.Constants.AuthStatus.ANONYMOUS, navSettings);
        //remove the splash-page checked flag and allow to re-fetch on next login within the same session
        MDM.SharedNav.Template.SplashPage.toggleSplashPageCheck(false);
    }

    checkLocalSession() {
        return MDM.SharedNav.Utils.makeRequest({
            method: 'GET',
            url: `${MDM.SharedNav.Params.navUrl}/account/navparams`,
            withCredentials: true
        });
    }

    isAuthenticatedLocal() {
        return this.checkLocalSession()
            .then((response) => {
                var parsedResponse = JSON.parse(response);
                MDM.SharedNav.Params.onlineUrl = parsedResponse.mdUrl;
                //only trigger endSilentLogin when user is authenticated locally
                if (parsedResponse.userProfile.isAuthenticated === false) {
                    this.isAuthenticatedOidc(parsedResponse);
                } else {
                    MDM.SharedNav.Core.StateManagement.triggerAuthenticatedProcess(parsedResponse);
                }
            })
            .catch(function(error) {
                console.error(error);
            });
    }

    isAuthenticatedOidc(authenticationObject) {
        MDM.SharedNav.Utils.makeRequest({
                method: 'GET',
                url: `${authenticationObject.authority}/sessionstate?ClientId=${authenticationObject.clientId
                    }&nocache=${(new Date()).getTime()}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                withCredentials: true
            })
            .then((response) => {
                const parsedResponse = JSON.parse(response);

                if (parsedResponse.Success === false) {
                    //user is not Authenticated with the IP, nothing left to do
                    MDM.SharedNav.Core.StateManagement.triggerAnonymousProcess(authenticationObject);
                } else {
                    //user is Authenticated with IP, we need to silently log him/her
                   this.startSilentLogin(authenticationObject);
                }
            })
            .catch(function(error) {
                console.error(error);
                //default if there is an error is to trigger the anonymous process
                MDM.SharedNav.Core.StateManagement.triggerAnonymousProcess(authenticationObject);
            });
    }

    startSilentLogin(params) {
        //switch to authentication in progress mode
        MDM.SharedNav.Core.StateManagement.triggerIsAuthenticatingProcess(params);

        this.appendLoginFrame();
        //check silent login status every 250ms
        this.checkSessionEventId = setInterval(() => this.checkSilentLoginStatus(), 250);
        //force clearing checkSilentLoginStatus after 10s if there is an issue with login
        if (this.checkSessionEventId !== null) {
            setTimeout(() => clearInterval(this.checkSessionEventId), 10000);
        }
    }

    appendLoginFrame() {
        const iframe = document.createElement("iframe");
        iframe.setAttribute("src", `${MDM.SharedNav.Params.navUrl}/account/silentLogin`);
        iframe.setAttribute("aria-hidden", "true");
        iframe.style.width = "0px";
        iframe.style.height = "0px";
        iframe.id = "SharedNavSilentLoginIframe";
        iframe.style.visibility = "hidden";
        document.body.appendChild(iframe);
    }

    checkSilentLoginStatus() {
        return this.checkLocalSession()
            .then((response) => {
                var parsedResponse = JSON.parse(response);
                MDM.SharedNav.Params.onlineUrl = parsedResponse.mdUrl;
                if (parsedResponse.userProfile.isAuthenticated === true) {
                    this.endSilentLogin(true, parsedResponse);
                }
            })
            .catch((response) => {
                console.error(response);
                this.endSilentLogin(false);
            });
    }

    endSilentLogin(successfullyResolved, params) {
        clearInterval(this.checkSessionEventId);
        //update authentication bindings depending on resolved state
        if (successfullyResolved) {
            //init authenticated chat
            MDM.SharedNav.Core.StateManagement.triggerAuthenticatedProcess(params);
        } else {
            //init anonymous chat
            MDM.SharedNav.Core.StateManagement.triggerAnonymousProcess(params);
        }
    }

    toggleSurvey(state) {
        if (state.isMedalliaEnabled === false) {
            if (MDM.SharedNav.Template.forseeInstance !== null) {
                MDM.SharedNav.Template.forseeInstance.toggle(state);
            }
        }
    }
};
