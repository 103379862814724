/* requires:
core-polyfills.js
*/
var MDM = MDM || {};
MDM.SharedNav = MDM.SharedNav || {};
MDM.SharedNav.NavElement = MDM.SharedNav.NavElement || document.getElementById("shared-nav");



MDM.SharedNav.Params = {
    /**
    * Converts a string to a boolean
    * @description
     * This conversion will:     *
     *  - match 'true' as true.
     *  - ignore all white-space padding
     *  - ignore capitalization (case).     *
     * 'tRue','TRUE', and ' True   ' will all evaluate as true.
    * @param  {string} inputString The string to evaluate
    * @returns {boolean} the boolean response
    */
    strToBool: function (inputString) {
        const regex = /^\s*true\s*$/i;

        return regex.test(inputString);
    },
    //required params
    template: MDM.SharedNav.NavElement.getAttribute('data-md-template'),
    navUrl: `https://${(new URL(document.currentScript.src)).hostname}`,
    onlineUrl: MDM.SharedNav.NavElement.getAttribute('data-md-onlineUrl'),
    //optional params
    get getFooter() {
         return this.strToBool(MDM.SharedNav.NavElement.getAttribute('data-md-getFooter'));
    },
    get getChat() {
        return this.strToBool(MDM.SharedNav.NavElement.getAttribute('data-md-getChat'));
    },
    get getSurvey() {
        return this.strToBool(MDM.SharedNav.NavElement.getAttribute('data-md-getSurvey'));
    },
    language: MDM.SharedNav.NavElement.getAttribute('data-md-language'),
    langToggleElementId: MDM.SharedNav.NavElement.getAttribute('data-md-langToggleElementId'),
    theme: MDM.SharedNav.NavElement.getAttribute('data-md-theme'),
    //callbacks
    toggleCallback: MDM.SharedNav.NavElement.getAttribute('data-md-toggleCallback'),
    loadCallback: MDM.SharedNav.NavElement.getAttribute('data-md-loadCallback'),
    loadSplashPageCallback: MDM.SharedNav.NavElement.getAttribute('data-md-loadSplashPageCallback'),
    activitiesLoadedCallback: MDM.SharedNav.NavElement.getAttribute('data-md-activitiesLoadedCallback')
};

MDM.SharedNav.Constants = {
    DefaultRetryCount: 2, //Number of retries (2 retries mean the request will be sent 3 times in total)
    DefaultRetryDelay: 0, //in milliseconds
    MdCookieDomain: '.mdm.ca',
    NavTemplates: {
        MD: 'md',
        MD_UBERFLIP: 'md-uberflip',
        MD_CAMPAIGN: 'md-campaign',
        MYMD: 'mymd',
        MYMD_MDDT: 'mymd-mddt',
        MYMD_MDEXO: 'mymd-mdexo',
        MD_ICONIC: 'md-iconic',
        MD_CAMPAIGNICONIC: 'md-campaign-iconic'
    },
    AuthStatus: {
        ANONYMOUS: -1,
        AUTHENTICATING: 0,
        AUTHENTICATED: 1
    },
    Chat: {
        SKIP_PRE_CHAT: 'skip-pre-chat'
    },
    Months: {
        0: {
            'fr': 'janvier',
            'en': 'January'
        },
        1: {
            'fr': 'f' + '\xE9' + 'vrier',
            'en': 'February'
        },
        2: {
            'fr': 'mars',
            'en': 'March'
        },
        3: {
            'fr': 'avril',
            'en': 'April'
        }
        ,
        4: {
            'fr': 'mai',
            'en': 'May'
        },
        5: {
            'fr': 'juin',
            'en': 'June'
        },
        6: {
            'fr': 'juillet',
            'en': 'July'
        },
        7: {
            'fr': 'ao' + '\xFB' + 't',
            'en': 'August'
        },
        8: {
            'fr': 'septembre',
            'en': 'September'
        },
        9: {
            'fr': 'octobre',
            'en': 'October'
        },
        10: {
            'fr': 'novembre',
            'en': 'November'
        },
        11: {
            'fr': 'd' + '\xE9' + 'cembre',
            'en': 'December'
        }
    }
};
